import React, { useState, useCallback } from 'react'
import Button from '../../Shared/Button'
import { sendMessage } from '../../../utils/socket'
import styles from './Composer.module.css'

export default function Composer() {
  const [value, setValue] = useState('')

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      sendMessage(value)
      setValue('')
    },
    [value]
  )

  const handleChange = useCallback(
    event => {
      setValue(event.target.value)
    },
    [setValue]
  )

  return (
    <div className={styles.wrapper}>
      <form className={styles.innerWrapper} onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Type a message..."
          className={styles.input}
          value={value}
          onChange={handleChange}
        />
        <Button primary type="submit">
          Send
        </Button>
      </form>
    </div>
  )
}
