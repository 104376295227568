import React, { useState, useCallback, useEffect } from 'react'
import { NavLink, Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { getCurrentOrganizationId } from '../../../ducks/users'

import {
  fetchViews,
  getNavHierarchy,
  getSearchTerm,
} from '../../../ducks/views'

import EventSuppressor from '../EventSuppressor'
import AccountMenu from './AccountMenu'
import Search from './Search'
import Add from './Add'
import Items from './Items'
import Emoji from '../Emoji'
import { getNextTarget, getNumericCommands } from '../../../utils/commands'

import styles from './Navbar.module.css'

const UP = 38
const DOWN = 40
const RETURN = 13

const ToggleButton = function ToggleButton({ onClick }) {
  return (
    <a className={styles.toggleButton} onTouchEnd={onClick} onClick={onClick}>
      <span />
      <span />
      <span />
    </a>
  )
}

export default function Navbar({ bannerActive }) {
  const [open, setOpen] = useState(false)
  const [focussedId, setFocussedId] = useState(null)
  const [searchActive, setSearchActive] = useState(false)
  const views = useSelector(getNavHierarchy)
  const orgId = useSelector(getCurrentOrganizationId)
  const dispatch = useDispatch()
  const history = useHistory()
  const searchTerm = useSelector(getSearchTerm)

  useEffect(() => {
    dispatch(fetchViews(orgId))
  }, [orgId])

  const handleClose = useCallback(
    e => {
      e.preventDefault()
      setOpen(false)
    },
    [setOpen]
  )

  const handleCloseAsync = useCallback(
    e => {
      if (e.target.tagName === 'A') {
        window.setTimeout(() => {
          setOpen(false)
        }, 20)
      }
    },
    [setOpen]
  )

  const handleOpen = useCallback(
    e => {
      e.preventDefault()
      setOpen(true)
    },
    [setOpen]
  )

  const handleFocus = useCallback(() => setSearchActive(true), [])
  const handleBlur = useCallback(() => setSearchActive(false), [])

  const handleKeyDown = useCallback(
    e => {
      if (e.which === RETURN) {
        e.preventDefault()

        // Select first item in list

        if (focussedId && searchTerm) {
          history.push(`/views/${focussedId}`)
        }

        // Blur the input
        e.target.blur()
      } else if (e.which === UP) {
        e.preventDefault()
        const newId = getNextTarget(views, focussedId, true)
        setFocussedId(newId)
      } else if (e.which === DOWN) {
        e.preventDefault()
        const newId = getNextTarget(views, focussedId)
        setFocussedId(newId)
      }
    },
    [views, searchTerm, focussedId]
  )

  const numericCommands = getNumericCommands(views)

  useEffect(() => {
    if (!searchTerm) {
      setFocussedId(null)
    } else {
      const nextTarget = getNextTarget(views)
      setFocussedId(nextTarget)
    }
  }, [searchTerm])

  const firstView = Object.keys(numericCommands).find(
    k => +numericCommands[k] === 1
  )

  if (window.location.pathname === '/' && views.length > 0 && firstView) {
    const url = `/views/${firstView}`

    history.replace(url)
  }

  return (
    <>
      <ToggleButton onClick={handleOpen} />
      <header
        className={classNames(
          styles.header,
          bannerActive && styles.bannerActive,
          open && styles.open
        )}
        onClick={handleClose}
        onTouchEnd={handleCloseAsync}
        onMouseUp={handleCloseAsync}
      >
        <div className={styles.innerWrapper}>
          <a
            onClick={handleClose}
            onTouchEnd={handleClose}
            className={styles.closeButton}
          >
            Close
          </a>
          <Link to="/" className={styles.brandWrapper}>
            <Brand />
          </Link>
          <div className={styles.nav}>
            <div>
              {/*
              <li>
                <NavLink
                  exact
                  to="/"
                  className={styles.link}
                  activeClassName={styles.active}
                >
                  Dashboard
                </NavLink>
              </li>
              */}
              <li className={styles.assistant}>
                <NavLink
                  exact
                  to="/assistant"
                  className={styles.link}
                  activeClassName={styles.active}
                >
                  <span className={styles.icon}>
                    <Emoji emoji="alien" size="sm" className={styles.emoji} />
                  </span>
                  Assistant
                </NavLink>
              </li>
              <EventSuppressor className={styles.search}>
                <Search
                  underline
                  name="navbar"
                  commandKey="k"
                  placeholder="Search Views, ⌘K"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                />
              </EventSuppressor>
              <Items
                views={views}
                searchTerm={searchTerm}
                focussedId={searchActive && focussedId}
                numericCommands={numericCommands}
              />
            </div>
            <Add />
          </div>
        </div>
        <div className={styles.account}>
          <AccountMenu />
        </div>
        {/*organization && !organization.activePlan && (
          <div className={styles.footerLinks}>
            <p>
              Want to support Inventora?
              <a
                href="https://buymeacoffee.com/inventora"
                target="_blank"
                rel="noreferrer noopener"
                className={styles.menuItem}
                onClick={e => e.stopPropagation()}
              >
                Buy us a coffee »
              </a>
            </p>
          </div>
        )*/}
      </header>
    </>
  )
}

export const Brand = function NavbarBrand({ className }) {
  return <span className={classNames(styles.brand, className)}>SQLDash</span>
}
