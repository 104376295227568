import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { VelocityTransitionGroup } from 'velocity-react'

import { toggle, getExpanded } from '../../../ducks/accordion'
import Icon from '../Icon'

import styles from './Accordion.module.css'

const renderChildren = children => {
  if (typeof children === 'function') {
    return children()
  }

  return children
}

const BaseAccordion = function BaseAccordion({
  children,
  title,
  className,
  expandedClassName,
  expanded,
  indented,
  toggleExpanded,
  carret,
  tight,
}) {
  return (
    <div
      className={classNames(
        styles.wrapper,
        className,
        expanded && styles.expanded,
        expanded && expandedClassName,
        indented && styles.indented,
        tight && styles.tight
      )}
    >
      <div className={styles.title} onClick={toggleExpanded}>
        {carret && (
          <div className={styles.carret}>
            <Icon
              icon="carret-filled"
              color="grey"
              className={styles.carretIcon}
            />
          </div>
        )}
        <div className={styles.titleSub}>{title}</div>
      </div>
      <div className={styles.children}>
        <VelocityTransitionGroup
          enter={{ animation: 'slideDown' }}
          leave={{ animation: 'slideUp' }}
        >
          {expanded ? renderChildren(children) : null}
        </VelocityTransitionGroup>
      </div>
    </div>
  )
}

const StateAccordion = function StateAccordion(props) {
  const [expanded, setExpanded] = useState(false)

  const handleToggle = () => {
    setExpanded(!expanded)
  }

  return (
    <BaseAccordion
      {...props}
      expanded={expanded}
      toggleExpanded={handleToggle}
    />
  )
}

const GroupedAccordion = function GroupedAccordion({ id, group, ...props }) {
  const expanded = useSelector(getExpanded(group, id))
  const dispatch = useDispatch()

  const handleToggle = useCallback(() => {
    dispatch(toggle(group, id))
  }, [group, id, dispatch])

  return (
    <BaseAccordion
      {...props}
      expanded={expanded}
      toggleExpanded={handleToggle}
    />
  )
}

export default function Accordion({ id, group, ...props }) {
  if (id && group) {
    return <GroupedAccordion {...props} id={id} group={group} />
  } else {
    return <StateAccordion {...props} />
  }
}
