import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getMessages, fetchRecentMessages } from '../../ducks/messages'
import { getCurrentOrganizationId } from '../../ducks/users'
import Emoji from '../Shared/Emoji'
import Messages from './Messages'
import Composer from './Composer'
import styles from './Assistant.module.css'

// const EXAMPLE_MESSAGES = [
//   {
//     role: 'user',
//     message: 'How many new users signed up in the past 12 months?',
//     createdAt: '2025-02-20T14:00:00.000Z',
//   },
//   {
//     role: 'assistant',
//     message:
//       'Hi, I’m happy to help you research how many users signed up in the past 12 months (trailing 12 months).',
//     createdAt: '2025-02-20T14:00:01.000Z',
//   },
//   {
//     role: 'assistant',
//     type: 'query',
//     message:
//       'SELECT COUNT(*), MAX("createdAt") FROM users WHERE "createdAt" >= NOW() - INTERVAL 12 MONTH',
//     data: [
//       {
//         count: 425,
//         max: '2024-12-20T14:00:02.000Z',
//       },
//     ],
//     createdAt: '2025-02-20T14:00:02.000Z',
//   },
//   {
//     role: 'assistant',
//     message:
//       'This query filters organizations where the createdAt timestamp falls within December 2024 and counts them. Let me know if you need any modifications!',
//     createdAt: '2025-02-20T14:00:03.000Z',
//   },
// ]

export default function Assistant() {
  const messages = useSelector(getMessages)
  const dispatch = useDispatch()
  const orgId = useSelector(getCurrentOrganizationId)

  useEffect(() => {
    dispatch(fetchRecentMessages(orgId))
  }, [dispatch, orgId])

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Emoji emoji="alien" size="lg" />
        <h1>Assistant</h1>
      </div>
      <Messages messages={messages} />
      <Composer />
    </div>
  )
}
