import React, { useCallback, useState, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { formatLabel } from '../../../utils/strings'
import styles from './Table.module.css'

const DEFAULT_WIDTH = 200
const MIN_WIDTH = 60

const Cell = function Cell({ column, children }) {
  const { width = DEFAULT_WIDTH } = column

  const customStyles = {
    width,
  }

  return (
    <div className={styles.cell} style={customStyles}>
      {children}
    </div>
  )
}

const HeaderCell = function HeaderCell({ children, column, onResize }) {
  const [initial, setInitial] = useState(null)
  const [newWidth, setNewWidth] = useState(null)

  const handleMouseDown = useCallback(
    e => {
      e.preventDefault()

      if (!onResize) return

      const { width = DEFAULT_WIDTH } = column
      const startPoint = [e.clientX, e.clientY]

      setInitial({ width, startPoint })
      setNewWidth(width)
    },
    [column.width, onResize]
  )

  const handleMouseMove = useCallback(
    e => {
      e.preventDefault()

      if (!initial || !onResize) return

      const { startPoint, width } = initial

      const diff = e.clientX - startPoint[0]
      const newWidth = Math.max(MIN_WIDTH, width + diff)

      setNewWidth(newWidth)
    },
    [initial, onResize, setNewWidth]
  )

  const handleMouseUp = useCallback(
    e => {
      if (!onResize) return

      const { width = DEFAULT_WIDTH } = column

      setInitial(null)

      if (newWidth !== width) {
        onResize(column.name, newWidth)
      }
    },
    [column, newWidth, onResize]
  )

  useEffect(() => {
    if (!initial) return

    document.body.addEventListener('mousemove', handleMouseMove)
    document.body.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.body.removeEventListener('mousemove', handleMouseMove)
      document.body.removeEventListener('mouseup', handleMouseUp)
    }
  }, [initial, handleMouseMove, handleMouseUp])

  const childColumn = initial ? { ...column, width: newWidth } : column

  return (
    <div className={styles.headerCell}>
      <Cell column={childColumn}>{formatLabel(column.name)}</Cell>
      <div className={styles.resizeHandle} onMouseDown={handleMouseDown} />
    </div>
  )
}

const Row = function Row({ columns, row, getRowColor }) {
  const backgroundColor = useMemo(
    () => getRowColor && getRowColor(row),
    [row, getRowColor]
  )

  return (
    <div
      className={styles.row}
      style={{ backgroundColor: backgroundColor || '#fff' }}
    >
      {columns.map(column => (
        <Cell key={column.name} column={column}>
          {column.renderCell ? column.renderCell({ row }) : row[column.name]}
        </Cell>
      ))}
    </div>
  )
}

export default function Table({
  columns,
  data,
  onResizeColumn,
  getRowColor,
  assistant = false,
}) {
  const customStyles = {
    width:
      columns.map(c => c.width || DEFAULT_WIDTH).reduce((a, b) => a + b, 0) +
      80,
  }

  return (
    <div
      className={classNames(styles.table, assistant && styles.assistant)}
      style={customStyles}
    >
      <div className={styles.header}>
        {columns.map(column => (
          <HeaderCell
            key={column.name}
            onResize={onResizeColumn}
            column={column}
          />
        ))}
      </div>
      {data.map((row, index) => (
        <Row
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          columns={columns}
          row={row}
          getRowColor={getRowColor}
        />
      ))}
    </div>
  )
}
