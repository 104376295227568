import { indexOf } from '@sqldash/common'
import * as API from '../utils/api'

const FETCH_RECENT = 'FETCH_RECENT'
const SEND_MESSAGE = Symbol('SEND_MESSAGE')

const INITIAL_STATE = {
  messages: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${FETCH_RECENT}_FULFILLED`: {
      return {
        ...state,
        messages: action.payload.data,
      }
    }

    case SEND_MESSAGE: {
      const message = action.payload

      if (!message.id) {
        message.id = `${+new Date()}-${Math.round(Math.random() * 10000)}`
      }

      const messages = [...state.messages]

      const index = indexOf(messages, m => m.id === message.id)

      if (index > -1) {
        messages[index] = message
      } else {
        messages.push(message)
      }

      return {
        ...state,
        messages,
      }
    }

    default:
      return state
  }
}

// Actions

export const fetchRecentMessages = organizationId => ({
  type: FETCH_RECENT,
  payload: API.fetchRecentMessages(organizationId),
})

export const sendMessage = messageObject => ({
  type: SEND_MESSAGE,
  payload: messageObject,
})

// Selectors

export const getMessages = state => {
  return state.messages.messages
}
