import React from 'react'
import * as Sentry from '@sentry/react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Page from '../Shared/Page'
import Auth, { AUTH_ROUTES } from '../Auth'
import AppLoader from './AppLoader'
import Account from '../Account'
import Modal from '../Shared/Modal'
import Settings from '../Settings'
import InviteUser from '../Settings/Users/Invite'
import CreateDatabase from '../Settings/Databases/CreateModal'
import EditDatabase from '../Settings/Databases/CreateModal/Edit'
import StripePortalRedirect from '../Settings/Billing/StripePortalRedirect'
import UpgradeSuccess from '../Subscriptions/UpgradeSuccess'
import UpgradeFeedback from '../Subscriptions/ShopifyUpgradeFeedback'
import UpgradeWatcher from '../Subscriptions/UpgradeWatcher'
import Subscribe from '../Subscriptions/Subscribe'
import UpdateCardModal from '../Subscriptions/UpdateCardModal'
import Homepage from '../Homepage'
import View from '../View'
import Assistant from '../Assistant'
import CreateFolder from '../Folders/CreateModal'
import EditFolder from '../Folders/CreateModal/Edit'
import ErrorFallback from './Error'

import './App.css'

function App() {
  return (
    <Modal.Provider>
      <Router>
        <Switch>
          <Route path={AUTH_ROUTES} component={Auth} />
          <Route
            exact
            path="/settings/billing"
            component={StripePortalRedirect}
          />
          <Route exact path="/subscribe" component={Subscribe} />
          <Route
            render={() => (
              <AppLoader>
                <Page>
                  <Switch>
                    <Route path="/account" component={Account} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/assistant" component={Assistant} />
                    <Route path="/views/:viewId" component={View} />
                    <Route exact path="/" component={Homepage} />
                  </Switch>
                </Page>
                <Route path="*/upgrade-success" component={UpgradeSuccess} />
                <Route path="*/upgrade-feedback" component={UpgradeFeedback} />
                <Route path="*/update-card" component={UpdateCardModal} />
                <Route
                  path="/:page(settings|production)/invite"
                  component={InviteUser}
                />
                <Route path="*/databases/new" component={CreateDatabase} />
                <Route
                  path="*/databases/:databaseId/edit"
                  component={EditDatabase}
                />
              </AppLoader>
            )}
          />
        </Switch>
        <Route component={UpgradeWatcher} />
        <Route path="*/folders/new" component={CreateFolder} />
        <Route path="*/folders/:folderId/edit" component={EditFolder} />
      </Router>
    </Modal.Provider>
  )
}

const Wrapper = function Wrapper({ children }) {
  if (process.env.NODE_ENV !== 'production') {
    return <App />
  }

  const fallback = <ErrorFallback />

  return (
    <Sentry.ErrorBoundary showDialog fallback={fallback}>
      <App />
    </Sentry.ErrorBoundary>
  )
}

export default Wrapper
