import React, { useEffect, useRef, useCallback } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import Markdown from 'react-markdown'
import * as API from '../../utils/api'
import { reRunQuery } from '../../utils/socket'
import { getCurrentOrganizationId } from '../../ducks/users'
import { createView, getNextPosition } from '../../ducks/views'
import Accordion from '../Shared/Accordion'
import Table from '../View/Data/Table'
import Icon from '../Shared/Icon'
import Loading from '../Shared/Loading'
import styles from './Assistant.module.css'

const ToolBar = function ToolBar({ message }) {
  const orgId = useSelector(getCurrentOrganizationId)
  const dispatch = useDispatch()
  const nextPosition = useSelector(getNextPosition)

  const handleCreateView = useCallback(() => {
    const query = message.message
    const viewName = prompt('Enter view name')

    if (viewName) {
      dispatch(
        createView(orgId, { name: viewName, query, order: nextPosition })
      )
    }
  }, [message, nextPosition])

  const handleReRunQuery = useCallback(() => {
    reRunQuery(message.id)
  }, [message.id])

  const handleDownload = useCallback(() => {
    API.downloadMessageCSV(message.id)
  }, [message.id])

  return (
    <div>
      <div className={styles.preAccordion}>
        <a onClick={handleCreateView} className={styles.createView}>
          <Icon inline icon="add" color="grey" />
          Create View
        </a>
        <a onClick={handleReRunQuery} className={styles.createView}>
          <Icon inline icon="reload" color="grey" />
          Run Again
        </a>
        <a onClick={handleDownload} className={styles.createView}>
          <Icon inline icon="arrow-down" color="grey" />
          Download CSV
        </a>
      </div>
      <Accordion
        carret
        title={
          <p className={styles.sqlTitle}>
            <a>Show Query</a>
          </p>
        }
        className={styles.sql}
        expandedClassName={styles.sqlExpanded}
      >
        <pre className={styles.sqlDetail}>{message.message}</pre>
      </Accordion>
    </div>
  )
}

const QueryMessage = function QueryMessage({ message }) {
  const data = message.data.result?.data

  if (message.data?.loading) {
    return (
      <div className={styles.query}>
        <div className={styles.loading}>
          <Loading small />
          <p className={styles.loadingText}>Running Query...</p>
        </div>
        <ToolBar message={message} />
      </div>
    )
  }

  if (!data || message.data.error) {
    return (
      <div className={styles.query}>
        <div className={styles.error}>
          <h3 className={styles.errorTitle}>Error</h3>
          <p className={styles.errorMessage}>
            {message.data.error?.message || 'Unknown error'}
          </p>
        </div>
        <ToolBar message={message} />
      </div>
    )
  }

  const rows = message.data.result?.data
  const count = rows.length
  const totalCount = message.data.result?.pagination?.totalResults

  return (
    <div className={styles.query}>
      <Table assistant result={message.data.result} />
      {totalCount && +totalCount > count ? (
        <p className={styles.count}>
          Showing {count} of {totalCount} rows
        </p>
      ) : (
        <p className={styles.count}>
          {count} row{count === 1 ? '' : 's'}
        </p>
      )}
      <ToolBar message={message} />
    </div>
  )
}

export default function Messages({ messages }) {
  const messagesEndRef = useRef(null)
  const scrollFrameRef = useRef(null)
  const innerRef = useRef(null)

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages.length])

  return (
    <div className={styles.messages} ref={scrollFrameRef}>
      <div className={styles.messagesInner} ref={innerRef}>
        {messages.map(message =>
          message.type === 'query' ? (
            <QueryMessage key={message.id} message={message} />
          ) : (
            <div
              key={message.id}
              className={classNames(
                styles.message,
                message.role === 'user' && styles.userMessage,
                message.role === 'assistant' && styles.assistantMessage
              )}
            >
              <Markdown>{message.message}</Markdown>
            </div>
          )
        )}
        <div ref={messagesEndRef} />
      </div>
    </div>
  )
}
