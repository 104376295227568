import { io } from 'socket.io-client'
import { sendMessage as sendMessageSub } from '../ducks/messages'
import store from '../redux-store'

let socket

const apiURL = process.env.REACT_APP_COMBINED_APP
  ? ''
  : process.env.REACT_APP_BACKEND_URL

export const connectSocket = (authToken, orgId) => {
  if (socket) {
    socket.disconnect()
  }

  // Create new socket connection
  socket = io(apiURL, {
    query: { authToken, orgId },
  })

  socket.on('message', message => {
    store.dispatch(sendMessageSub(message))
  })
}

export const sendMessage = message => {
  const messageObj = {
    message,
    createdAt: new Date(),
    role: 'user',
  }

  socket.emit('message', messageObj)

  store.dispatch(sendMessageSub(messageObj))
}

export const reRunQuery = messageId => {
  socket.emit('reRunQuery', messageId)
}
